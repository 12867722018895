<template>
  <div>
    <v-dialog v-model="dialog" max-width="500" persistent>
      <v-card>
        <v-card-title>
          {{ dialogTitle }}
          <v-spacer/>
          <v-icon @click="close">mdi-close</v-icon>
        </v-card-title>

        <v-divider/>

        <v-card-text class="pa-8">

          <v-text-field v-model="form.title"
                        :error-messages="errors.title"
                        label="Titre *"
                        @input="errors && errors.title ? errors.title = '' : false"/>

          <v-text-field v-model="form.winning_points"
                        :error-messages="errors.winning_points"
                        label="Points gagnants *"
                        suffix="Pts"
                        @input="errors && errors.winning_points ? errors.winning_points = '' : false"/>

          <v-textarea v-model="form.description"
                      :error-messages="errors.description"
                      label="Description"
                      rows="2"
                      @input="errors && errors.description ? errors.description = '' : false"/>

        </v-card-text>

        <v-divider/>

        <v-card-actions>
          <v-spacer/>
          <v-btn :loading="btnLoading"
                 color="primary"
                 depressed
                 type="submit"
                 @click="save">
            <v-icon left>mdi-content-save</v-icon>
            Enregistrer
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
    data() {
        return {
            dialog: false,
            dialogTitle: null,

            btnLoading: false,
            form: {},
            errors: {},
        }
    },
    methods: {
        open(item) {
            this.errors = {}
            this.dialog = true
            this.dialogTitle = item ? "Modifier KIP" : "Ajouter KIP"
            if (item) {
                this.form = {
                    id: item.id,
                    title: item.title,
                    winning_points: item.winning_points,
                    description: item.description,
                }
            } else {
                this.form = {}
            }
        },
        close() {
            this.dialog = false
        },
        save() {
            this.btnLoading = true
            this.errors = {}
            this.$Progress.start()
            const url = this.form.id ? '/KPIs/update' : '/KPIs/store'
            HTTP.post(url, this.form).then(() => {
                this.$successMessage = 'Ce KPI a été enregistré avec succès'
                this.btnLoading = false
                this.$Progress.finish()
                this.$emit('refresh')
                if (this.form.id) {
                    this.close()
                } else {
                    this.form = {}
                }
            }).catch(err => {
                this.btnLoading = false
                this.$Progress.fail()
                this.errors = err.response.data.errors
                this.$errorMessage = "Une erreur ç'est produit veuillez le corriger."
                console.log(err)
            })
        },
    },
}
</script>

<style scoped>

</style>