<template>
  <div>
    <v-container fluid>

      <v-overlay :value="overlay" opacity="0">
        <v-progress-circular
                color="primary"
                indeterminate
                size="50"
        ></v-progress-circular>
      </v-overlay>

      <v-row>
        <v-col cols="12">
          <v-card class="rounded-lg shadow">

            <v-card-title>

              <div>
                <v-text-field v-model="search"
                              append-icon="mdi-magnify"
                              class="w-300" dense
                              filled
                              hide-details
                              placeholder="Recherche..."
                              rounded single-line></v-text-field>
              </div>

              <v-spacer/>

              <v-btn class="text-none" disabled text>
                {{ KPIs.length }} Élément(s)
              </v-btn>

              <v-tooltip color="primary" top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" @click="getKPIs" v-on="on">
                    <v-icon>mdi-refresh</v-icon>
                  </v-btn>
                </template>
                <span>Actualiser</span>
              </v-tooltip>

              <v-btn class="ml-1" color="blue" dark depressed fab small
                     @click="$refs.KPIForm.open()">
                <v-icon>mdi-plus</v-icon>
              </v-btn>

            </v-card-title>

            <v-skeleton-loader v-if="loading"
                               type="table"/>

            <v-data-table v-else
                          :headers="headers"
                          :items="KPIs"
                          :search="search">

              <template v-slot:item.winning_points="{ item }">
                <v-chip color="blue" dark small>
                  {{ item.winning_points }}
                </v-chip>
              </template>

              <template v-slot:item.actions="{ item }">
                <div class="d-flex">

                  <v-tooltip color="primary" top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="primary" icon v-bind="attrs"
                             @click="$refs.KPIForm.open(item)"
                             v-on="on">
                        <v-icon size="20">mdi-pencil-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Modifier</span>
                  </v-tooltip>

                  <v-tooltip color="primary" top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="primary" icon v-bind="attrs"
                             @click="deleteItem(item.id)"
                             v-on="on">
                        <v-icon size="20">mdi-trash-can-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Supprimer</span>
                  </v-tooltip>

                </div>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>

      <KPIForm ref="KPIForm" @refresh="getKPIs"/>

    </v-container>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";
import KPIForm from "./KPIForm.vue";

export default {
    components: {KPIForm},
    data() {
        return {
            search: '',
            headers: [
                {text: 'Titre', value: 'title'},
                {text: 'Description', value: 'description'},
                {text: 'Points gagnants', value: 'winning_points'},
                {text: 'Actions', value: 'actions', sortable: false},
            ],
            overlay: false,
            dialog: false,
            id: '',
            KPIs: [],
            loading: false,
        }
    },
    methods: {
        deleteItem(id) {
            this.id = id
            this.$confirm_dialog = true
        },
        getKPIs() {
            this.KPIs = []
            this.loading = true
            this.$Progress.start()
            HTTP.get('/KPIs').then((res) => {
                this.loading = false
                this.$Progress.finish()
                this.KPIs = res.data.data
            }).catch(err => {
                this.$Progress.fail()
                this.loading = false
                console.log(err)
            })
        },
    },
    created() {
        this.getKPIs()
    },
    watch: {
        '$is_confirm': function (el) {
            if (el) {
                this.$is_confirm = false
                this.overlay = true
                this.$Progress.start()
                HTTP.delete('/KPIs/delete/' + this.id).then(() => {
                    let i = this.KPIs.findIndex(item => item.id === this.id)
                    this.$delete(this.KPIs, i)
                    this.$successMessage = 'Ce KPI a été supprimé avec succès'
                    this.overlay = false
                    this.$Progress.finish()
                }).catch(err => {
                    this.overlay = false
                    this.$Progress.fail()
                    console.log(err)
                })
            }
        },
    },
}
</script>

<style scoped>

</style>